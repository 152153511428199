import { Dispatch } from 'redux'
import { AddressBook, Department, Pause, Status, User } from '../../api/rest'
import { SET_ME } from '../login/types'
import { getAddressBooks, getDepartments, getMe, getPauses, getUser, getUsers } from '../selectors'
import { RootState } from '../types'
import * as t from './types'
import { setTheme } from '../theme/actions'
import { setTranslation } from '../language/actions'
import { getTranslation } from '../../helpers/getTranslation'
import store from '..'

export const usersList = (users: Map<number, User>) => (dispatch: Dispatch, getState: () => RootState) => {
  const loggedUser = getUser(getState())
  if (!loggedUser) return
  const me = users.get(loggedUser.id)
  users.delete(loggedUser.id)
  dispatch({ type: t.USERS_LIST, payload: users })
  dispatch({ type: SET_ME, payload: me })
}

export const usersUpdate = (user: User) => (dispatch: Dispatch, getState: () => RootState) => {
  const me = getMe(getState())
  if (user.id === me.id) {
    if (user.theme !== me.theme) {
      dispatch(setTheme(user.theme))
    }
    if (user.language?.code !== me.language?.code) {
      getTranslation(user.language?.code!).then((translation) => {
        user.language?.code && store.dispatch(setTranslation(user.language.code, translation))
      })
    }
    dispatch({ type: SET_ME, payload: user })
  } else {
    const users = getUsers(getState())
    const newUsers = new Map(users)
    newUsers.set(user.id, user)
    dispatch({ type: t.USERS_LIST, payload: newUsers })
  }
}

export const addressbooksList = (addressbooks: Map<number, AddressBook>) => ({
  type: t.ADDRESSBOOKS_LIST,
  payload: addressbooks
})

export const addressbooksUpdate = (addressbook: AddressBook) => (dispatch: Dispatch, getState: () => RootState) => {
  const addressbooks = getAddressBooks(getState())
  const newAddressbooks = new Map(addressbooks)
  newAddressbooks.set(addressbook.id, addressbook)
  dispatch({ type: t.ADDRESSBOOKS_LIST, payload: newAddressbooks })
}

export const pausesList = (pauses: Pause[]): t.EntitiesStateActionTypes => ({
  type: t.PAUSES_LIST,
  payload: pauses
})

export const pausesUpdate = (pause: Pause) => (dispatch: Dispatch, getState: () => RootState) => {
  const pauses = getPauses(getState())
  dispatch({ type: t.PAUSES_LIST, payload: [...pauses, pause] })
}

export const statusesList = (statuses: Status[]): t.EntitiesStateActionTypes => ({
  type: t.STATUSES_LIST,
  payload: statuses
})

export const statusesUpdate = (status: Status) => (dispatch: Dispatch, getState: () => RootState) => {
  const statuses = getPauses(getState())
  dispatch({ type: t.PAUSES_LIST, payload: [...statuses, status] })
}

export const departmentsList = (departments: Map<number, Department>): t.EntitiesStateActionTypes => ({
  type: t.DEPARTMENTS_LIST,
  payload: departments
})

export const departmentsUpdate = (department: Department) => (dispatch: Dispatch, getState: () => RootState) => {
  const departments = getDepartments(getState())
  const newDepartments = new Map(departments)
  newDepartments.set(department.id, department)
  dispatch(departmentsList(newDepartments))
}
