import { LoginAction } from '../login/types'
import { AddressBook, Department, Outbound, Pause, Status, User } from '../../api/rest'

export const USERS_LIST = 'USERS_LIST'
export const ADDRESSBOOKS_LIST = 'ADDRESSBOOKS_LIST'
export const PAUSES_LIST = 'PAUSES_LIST'
export const STATUSES_LIST = 'STATUSES_LIST'
export const DEPARTMENTS_LIST = 'DEPARTMENTS_LIST'

export interface EntitiesState {
  users: Map<number, User>
  addressbooks: Map<number, AddressBook>
  pauses: Pause[]
  statuses: Status[]
  departments: Map<number, Department>
  outbounds: Outbound[]
}

export interface UsersListAction {
  type: typeof USERS_LIST
  payload: Map<number, User>
}

export interface AddressBooksListAction {
  type: typeof ADDRESSBOOKS_LIST
  payload: Map<number, AddressBook>
}

export interface PausesListAction {
  type: typeof PAUSES_LIST
  payload: Pause[]
}

export interface StatusesListAction {
  type: typeof STATUSES_LIST
  payload: Status[]
}

export interface DepartmentsListAction {
  type: typeof DEPARTMENTS_LIST
  payload: Map<number, Department>
}

export type EntitiesStateActionTypes =
  | UsersListAction
  | AddressBooksListAction
  | PausesListAction
  | DepartmentsListAction
  | LoginAction
  | StatusesListAction
