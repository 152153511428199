import {MenuItem} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import {Path, useFormState, UseFormTrigger, useWatch} from 'react-hook-form'
import styled, {StyledComponent} from 'styled-components'
import {EntityData} from '../../hooks/useConfigurationPbxHelpers'
import {Select} from './Select'
import {TwoItemsRow} from './FormRows'
import {FieldValues} from "react-hook-form/dist/types";
import {useSelector} from 'react-redux'
import {getTranslation} from '../../store/selectors'
import {AutocompleteSelect} from './AutocompleteSelect'

export function DestinationSelect<FormData extends FieldValues>(props: Props<FormData>) {

  const translation = useSelector(getTranslation)

  const {
    control,
    hasPermission,
    Container = TwoItemsRow,
    availableDestTypes,
    getEntityData,
    destTypeLabel,
    destinationLabel,
    setValue,
    hint,
    fieldName,
    trigger,
    inputsStyle,
    disabled,
    rules,
    openUpSelect= false,
    openUpAutocomplete= false
  } = props

  const {isDirty, errors } = useFormState({control, name: `${fieldName}.destType`})

  const [availableDestinations, setAvailableDestinations] = useState<EntityData[]>([])

  const currentDestType = useWatch({
    control,
    name: `${fieldName}.destType`
  })

  const currentDestination = useWatch({
    control,
    name: `${fieldName}.destination`
  })

  const ShowEntitiesSelect = useMemo(() => {
    if (!currentDestType) return false
    return !(currentDestType === 'app-blackhole,hangup,1' || currentDestType === 'app-blackhole,busy,1' || currentDestType === 'ocrecall')
  }, [currentDestType])

  // Serve per settare la prima opzione sul campo entità destinazione come selezionata, quando si sceglie un tipo di entità
  useEffect(() => {
    const entityData = getEntityData(currentDestType)
    setAvailableDestinations(entityData)
    if (entityData.length === 0)setValue(`${fieldName}.destination`, '')
    else {
      isDirty && !entityData.find((e) => e.id === currentDestination) &&
        setValue(`${fieldName}.destination`, entityData[0].id, {shouldDirty: true})
    }
  }, [currentDestType, setAvailableDestinations, currentDestination, fieldName, setValue, isDirty, getEntityData])


  useEffect(() => {
    trigger && !!availableDestinations.length && trigger && trigger(`${fieldName}.destination` as Path<FormData>)
  }, [availableDestinations, trigger, fieldName])

  const getErrorMessage = ()=>{
    // @ts-ignore
    if(!errors || !errors[fieldName] || !errors[fieldName].destType) return
    // @ts-ignore
    if(errors[fieldName].destType.type === 'required' || errors[fieldName].destination.type === 'required') return translation.errors.required
  }

  return (
    <Container>
      <Select<FormData>
        name={`${fieldName}.destType` as Path<FormData>}
        control={control}
        label={destTypeLabel}
        hasPermission={hasPermission}
        rules={rules}
        disabled={disabled}
        containerStyle={inputsStyle}
        openUpSelect={openUpSelect}
        hint={!ShowEntitiesSelect ? hint : undefined}>
        <MenuItem key={'empty'} value={0}>
          {translation.input.emptySelect}
        </MenuItem>
        {Object.entries(availableDestTypes).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {ShowEntitiesSelect && (
        <AutocompleteSelect<FormData>
          name={`${fieldName}.destination` as Path<FormData>}
          size="medium"
          control={control}
          label={destinationLabel}
          disabled={disabled}
          hasPermission={hasPermission}
          containerStyle={inputsStyle}
          rules={{ required: true, validate: (value) => {
              return !availableDestinations.find((e) => e.id === value) ? 'badDestination' : true
            }}}
          hint={hint}
          options={availableDestinations.map(({ id, label }) => ({ key: id, label, value: id }))}
          menuPlacement={openUpAutocomplete ? 'top' : 'bottom'}>
        </AutocompleteSelect>
      )}
      {errors && <Error>{getErrorMessage()}</Error>}
    </Container>
  )
}

// region types
type Props<TFieldValues extends FieldValues> = {
  control: any
  errors?: any
  hasPermission: boolean
  destTypeLabel: string
  destinationLabel: string
  Container?: StyledComponent<"div", any, {justifyContent?: string}, any>
  hint?: string
  setValue: Function
  disabled?: boolean
  availableDestTypes: { [key: string]: string }
  getEntityData: (entityName: string) => EntityData[]
  fieldName: string
  rules?: any
  inputsStyle?: Object
  trigger?: UseFormTrigger<TFieldValues>
  openUpSelect?: boolean
  openUpAutocomplete?: boolean
}
// endregion

// region style
const Error = styled.div`
  grid-area: error;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 0.8rem;
  margin-left: ${(props) => props.theme.spacing(1)};
`

// endregion
