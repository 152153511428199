import {selectPbx, setButtonLoading, setPbxs, setWarnings} from '../../store/actions'
import {getIsAuthenticated, getTranslation, getUser} from '../../store/selectors'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Entities, useMonitoringCalls, useMonitoringChats, useRest, useSocket} from '../../hooks'
import usePermissions from '../../hooks/usePermissions'
import {logger} from '../../helpers'
import {WarningItem} from '../../store/applicationState/types'
import {DateTime} from 'luxon'
import {Queue} from '../../api/types'
import {waitForWebSocket} from '../hoc/waitForWebSocket'

const socketChannelsToJoin: Array<keyof Entities> = ['pbxs', 'departments']

const AppStateManager: React.FC = (): null => {
  const translation = useSelector(getTranslation)
  const loggedUser = useSelector(getUser)
  const calls = useMonitoringCalls()
  const chats = useMonitoringChats()

  const dispatch = useDispatch()
  const { hasPermissionTo } = usePermissions()

  const { post: applyPbxConfigurations } = useRest(`pbxs`)
  const { get: getQueues } = useRest<Queue>('queues')
  const isAuthenticated = useSelector(getIsAuthenticated)

  const {
    entities: { pbxs, departments }
  } = useSocket(socketChannelsToJoin)

  // carica i pbx su redux
  useEffect(() => {
    if (pbxs) {
      dispatch(setPbxs(pbxs))
      const first = pbxs[0]
      if (first) dispatch(selectPbx(first.id))
    }
  }, [pbxs, dispatch])

  // carica le notifiche su redux
  useEffect(() => {
    let departmentWarnings: WarningItem[] = []
    let pbxsReloadNotifications: WarningItem[] = []
    let queueWarnings: WarningItem[] = []
    if(isAuthenticated) {
      getQueues().then((data) => {
        const queues = data?.payload
        if (queues) {
          queueWarnings = queues.filter((queue) => {
            return queue.warningThreshold && [...calls.values()].reduce((acc, call) => acc + (call.queues?.includes(queue.id!) ? 1 : 0), 0) >= queue.warningThreshold
          }).map((queue) => {
            return ({
              severity: 'error.main',
              timestamp: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'),
              message: translation.notifications.queueWarningThreshold.replace(/<.*>/g, queue.descr)
            })
          }) as WarningItem[]
        }
      }).catch((error) => {
        logger.error(error)
      })
    }
    if (departments && departments.length) {
      departmentWarnings = (departments?.filter((department) => { return department.warningThreshold && [...chats.values()].reduce((acc, chat) => acc + (chat.departments.includes(department.id!) && !chat.operator ? 1 : 0), 0) >= department.warningThreshold }).map((department) => ({
        severity: 'error.main',
        timestamp: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'),
        message: translation.notifications.chatWarningThreshold.replace(/<.*>/g, department.name)
      }))) as WarningItem[]
    }

    // Le notifiche riguardanti il reload del pbx vanno mostrate solo se ci si è loggati con un utente BWAdmin
    if (pbxs && pbxs.length && hasPermissionTo('create', 'pbxs-commands')) {
      pbxsReloadNotifications = pbxs
        .filter((pbx) => pbx.needReload)
        .map((pbx) => ({
          severity: 'secondary.dark',
          timestamp: pbx.reloadTimestamp
            ? DateTime.fromFormat(pbx.reloadTimestamp, 'yyyy-MM-dd HH:mm:ss.SSS').toFormat('dd-MM-yyyy HH:mm:ss')
            : '',
          message: translation.notifications.pbxNeedsReload.replace(/<.*>/g, pbx.name),
          actionButton: {
            label: translation.input.applyConfigurations,
            onClick: () => {
              dispatch(setButtonLoading(true))
              applyPbxConfigurations({}, { afterPath: `/${pbx.id}/commands/reload` })
                .then(() => dispatch(setButtonLoading(false)))
                .catch((e) => logger.error(e))
            }
          }
        })) as WarningItem[]
    }
    dispatch(setWarnings([...queueWarnings, ...pbxsReloadNotifications, ...departmentWarnings]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    calls,
    chats,
    hasPermissionTo,
    applyPbxConfigurations,
    departments,
    loggedUser,
    pbxs,
    translation
  ])

  return null
}

export default waitForWebSocket(AppStateManager)
