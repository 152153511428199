
export const simplifiedEntityBlueprint = {
  'pbxs':['id'],
  'queues': ['id', 'pbx' ,'joinannounce_id', 'dest', 'music'],
  'inboundRoutes': ['id', 'pbx', 'destination', 'mohclass'],
  'announcements': ['announcement_id', 'post_dest', 'recording_id'],
  'extensions': ['extension', 'grptime'],
  'ivrs': ['id', 'announcement', 'invalid_destination', 'ivrEntries', 'invalid_retry_recording', 'invalid_recording', 'timeout_recording', 'timeout_retry_recording', 'timeout_destination' ],
  'musics-on-hold': ['id', 'category'],
  'recordings': ['id'],
  'ring-groups':['grpnum', 'annmsg_id', 'recording', 'postdest', 'grplist'],
  'timegroups': ['id'],
  'timeconditions':['timeconditions_id', 'truegoto', 'falsegoto', 'time']
}

const destTypeMap = new Map<string, string | null>([
  ['ext-queues', 'queues'],
  ['app-blackhole,hangup,1', null],
  ['app-blackhole,busy,1', null],
  ['ext-group', 'ring-groups'],
  ['from-did-direct', 'extensions'],
  ['timeconditions', 'timeconditions'],
  ['app-announcement', 'announcements'],
  ['ivr', 'ivrs']])

export const matchNameMap: any = {
  'pbxs': new Map(),
  'queues': new Map([['pbx', 'pbxs'], ['joinannounce_id', 'recordings'], ['music', 'musics-on-hold']]),
  'inboundRoutes': new Map([ ['mohclass', 'musics-on-hold'], ['pbx', 'pbxs'] ]),
  'announcements': new Map([ ['extension', 'extensions'], ['recording_id', 'recordings'] ]),
  'extensions': new Map([ ['grplist', 'extensions'] , ['grptime', 'timegroups']]),
  'ivrs': new Map([['announcement', 'recordings'] , ['invalid_retry_recording', 'recordings'], ['invalid_recording', 'recordings'],  ['timeout_recording', 'recordings'], ['timeout_retry_recording', 'recordings']]),
  'timeconditions': new Map([['time', 'timegroups']]) ,
  'timegroups': new Map(),
  'ring-groups' : new Map([['annmsg_id', 'recordings'],['ringing', 'musics-on-hold'], ['grplist', 'extensions']]),
  'musics-on-hold': new Map([['announcement', 'announcements']]),
  'recordings': new Map()
}

export type EntityType = 'pbxs' | 'queues' | 'inboundRoutes' | 'announcements' | 'extensions' | 'ivrs' | 'musics-on-hold' | 'recordings' | 'ring-groups' | 'timegroups' | 'timeconditions'

export const relationsParser = (entity: any, type: string): {destinations: string[], relations: string[], nameMap: Map<string, { name: string , state: string}>} => {
  let realType = type as EntityType
  if(type === 'voips') realType = 'extensions' as EntityType
  if(type === 'inbound-routes') realType = 'inboundRoutes' as EntityType
  const simplifiedEntity = simplifyEntity(entity, realType)

  if(Object.keys(simplifiedEntity).find((field)=> field === undefined)) return {destinations: [], relations: [], nameMap: new Map([])}

  const nameMap = new Map<string, { name: string , state: string}>([])
  const destinations: string[] = []
  const used: string[] = []
  const relations: string[] = entity?.relations.filter((rel: any)=> rel.state === 'static').map((rel: any)=> {
    nameMap.set(`${rel.type}:${rel.id}`, {name: rel.name, state: rel.state})
    return `${rel.type}:${rel.id}`
  })
  Object.keys(simplifiedEntity).forEach((field)=>{
    if(!simplifiedEntity[field] || simplifiedEntity[field] === '') return
    if(isDestination(field)) {
      const dest = parseDestination(simplifiedEntity[field])
      if(dest && relations?.includes(dest)) {
        const indexToRemove = relations.findIndex((entry: string)=> entry === dest)

        relations.splice(indexToRemove, 1)
        destinations.push(dest)

      }
    }
    else{
      let matchFieldName = field.includes('grplist') ? 'extensions' : matchNameMap[realType].get(field)
      if(matchFieldName) {
        const rel = `${matchFieldName}:${String(simplifiedEntity[field]) === 'default' ? '1' : simplifiedEntity[field]}`

        if(field.includes('grplist')){
          const indexToRemove = relations.findIndex((entry: string)=> entry === rel)

          relations.splice(indexToRemove, 1)
          destinations.push(rel)
        }
        if(relations && relations.includes(rel)) {
          const indexToRemove = relations.findIndex((entry: string)=> entry === rel)
          used.push(rel)
          relations.splice(indexToRemove, 1)
        }
      }
    }
    }
  )

  return {
    destinations: destinations,
    relations: relations,
    nameMap
  }
}


const isDestination = (field: string) =>  field.includes('dest') || field.includes('truegoto') || field.includes('falsegoto')


const simplifyEntity = (entity: any, type: EntityType) => {
  const fields = simplifiedEntityBlueprint[type]
  const simplifiedEntity: any = {}

  fields && fields?.forEach((field)=> {
    if(field === 'grplist' && entity[field]){
      entity[field].split('-').forEach((nested: string, index: number)=> {
        simplifiedEntity[`${field}_${index}`] = nested
      })
      simplifiedEntity[field] = ''
    }
    else if(typeof entity[field] === "string" || typeof entity[field] === 'number'){
      if(field === 'ringing' && entity[field] && (entity[field] === 'none' || entity[field] === 'Ring')) return
      simplifiedEntity[field] = entity[field]
    }
    else{
      if(type === "ivrs" && field === 'ivrEntries' && entity[field]){
        entity[field].forEach((nested: any, index: number)=> {
          simplifiedEntity[`dest_${index}`] = nested.dest
        })
      }else{
        simplifiedEntity[field] = entity[field]?.id
      }
    }
  })

  return simplifiedEntity
}



const parseDestination = (destination: string): string | undefined => {
  if(!destination) return
  let [destType, destItem] = destination && destination.split(',')
  if(destItem && destItem.includes('%7C')) destItem = destItem.replace('%7C', '|')
  if(destType.includes('ivr')){
    [destType, destItem] = destType.split('-')
  }
  if(destType.includes('app-announcement')){
    const destTypeChunks = destType.split('-')
    destItem = destTypeChunks.pop() as string
    destType = destTypeChunks.join('-')
  }
  const realDestType = destTypeMap.get(destType)
  return realDestType ? `${realDestType}:${destItem === 'default' ? 1 : destItem}` : undefined
}

